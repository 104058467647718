import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增机具统计数据
export const UpdateMachineryStatistics=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/UpdateMachineryStatistics`,
        data:datas
    })
}

// 删除机具统计数据
export const DeleteMachineryStatisticsList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/DeleteMachineryStatisticsList`,
        params:datas
    })
}


// 获取导航列表
export const GetMenuList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/GetMenuList`,
        params:datas
    })
}


// 获取权限列表
export const RoleList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/RoleList`,
        params:datas
    })
}

// 新增或修改权限
export const AddRole=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/AddRole`,
        data:datas
    })
}

// 删除角色 （权限）
export const DeleteRole=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/DeleteRole`,
        params:datas
    })
}

// 获取权限详情
export const GetRole=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/GetRole`,
        params:datas
    })
}

// 获取用户列表
export const GetPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/GetPageList`,
        params:datas
    })
}

// 删除用户
export const DeleteUser=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/DeleteUser`,
        params:datas
    })
}

// 新增或修改用户
export const AddUser=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/AddUser`,
        data:datas
    })
}

// 冻结或解冻
export const IsFrozen=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/IsFrozen`,
        params:datas
    })
}

//判断用户名唯一
export const GetJudgeUserIsExist=(datas)=>{ 
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/GetJudgeUserIsExist`,
        params:datas
    })
}
