<template>
  <div id='OV'>
    <div class="sedit btn" v-if = "editstatus == 1" @click="startEdit">更改</div>
   <div class="show-c" v-if = "editstatus == 1">
     <div class="line-c">
       <span class = "l-title">合同额</span>
       <span class = "finput">{{input1}}</span>
       <span class = "l-end">万元</span>
     </div>
     <div class="line-c">
       <span class = "l-title">变更额</span>
       <span class = "finput">{{input2}}</span>
       <span class = "l-end">万元</span>
     </div>
     <div class="line-c">
       <span class = "l-title">实收额</span>
       <span class = "finput">{{input3}}</span>
       <span class = "l-end">万元</span>
     </div>
   </div>
   <div class="edit-c" v-if = "editstatus == 2">
     <div class="line-c">
       <span class = "l-title">合同额</span>
        <el-input
          v-model="input1"
          placeholder="请输入"
          type="input"
          min="0"
          max="100"
        >
        </el-input>
       <span class = "l-end">万元</span>
     </div>
     <div class="line-c">
       <span class = "l-title">变更额</span>
        <el-input
          v-model="input2"
          placeholder="请输入"
          type="input"
        >
        </el-input>
       <span class = "l-end">万元</span>
     </div>
    <div class="line-c">
       <span class = "l-title">实收额</span>
        <el-input
          v-model="input3"
          placeholder="请输入"
          type="input"
        >
        </el-input>
       <span class = "l-end">万元</span>
     </div>
    <div class="btns-c" v-if = "editstatus == 2">
      <div class="btn" @click = "reset">重置</div>
      <div class="btn" @click = "save" :class = "{disable:!input1 || !input2|| !input3}">保存</div>
    </div>
   </div>

  </div>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage,ElMessageBox } from "element-plus";
import addjurisdiction from '@/components/addjurisdiction.vue'
import {UpdateCapitalRecycle,
GetCapitalRecycle} from '@/js/decision';
import { getStorage } from "@/js/common";
export default {
  name: '',
  props: {

  },

  setup (props) {
    const state = reactive({
      editstatus:1, // [1,2] [展示,编辑]
      input1:'',
      input2:'',
      input3:'',
      editid:''
    });
    const cdata = getStorage("bscdata");
    const getdata = ()=>{
      let datas = { 
        ProjectID: cdata.pid
      };
      GetCapitalRecycle(datas).then((res) => {
            console.log(res);
            let { Code,Data, Message } = res.data;
            if (Code == 1 && Data && Data.length) {
              let resdata = Data[0];
              state.input1 = resdata.ContractMoney;
              state.input2 = resdata.AlterationMoney;
              state.input3 = resdata.ReceivedMoney;
              // state.editid = resdata.ID;

            } else {
              // ElMessage({
              //   showClose: true,
              //   message: Message,
              //   type: "error",
              // });
            }
          });
    }
    const methods = {
      //
      startEdit: () => {
        state.editstatus = 2;
        
      },
      reset: () => {
        state.input1 = '';
        state.input2 = '';
        state.input3 = '';
        
      },
      //
      save () {
        if(state.input1 && state.input2 && state.input3){
          let datas = { 
            ProjectID: cdata.pid,
              "contractMoney": state.input1,
              "alterationMoney": state.input2, 
              "receivedMoney": state.input3, 
            };


          // if(state.editid){
          //   datas.id = state.editid;
          // }
          UpdateCapitalRecycle(datas).then((res) => {
            console.log(res);
            let { Code, Message } = res.data;
            if (Code == 1) {
              getdata();
              state.editstatus = 1;
            } else {
              ElMessage({
                showClose: true,
                message: Message,
                type: "error",
              });
            }
          });
        }
      }
    };


    onMounted(() => {
      getdata();
    });

    return {
      ...methods,
      ...toRefs(state)
    };

  },

  components: {
    addjurisdiction,
  }


}

</script>

<style lang='scss' scoped>
#OV {
  margin: 0;
  box-sizing: border-box;
  width:100%;
  height:100%;
  .btn{
      height:36px;
      line-height: 36px;
      background: #B1B7C4;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      color:#fff;
      padding:0 20px;
      background: #0083FF;
      display: inline-block;
      &.sedit{
        margin: 30px 0 0 86px;
      }
    }
  .show-c{
    width:100%;
    height:100%;
    padding:90px 0 0 90px;
  }
  .edit-c{
    width:100%;
    height:100%;
    padding:90px 0 0 90px;
  }
  .line-c{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333333;
    margin:0 0 20px;
    .l-title{
      width:100px;
    }
    .el-input,.finput,.el-input-number{
      width:200px;
      margin:0 10px 0 0;
    }
  }
  .btns-c{
    display: flex;
    margin:60px 0 0 ;
    .btn{
      height:36px;
      line-height: 36px;
      background: #B1B7C4;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      color:#fff;
      padding:0 20px;

      &:nth-child(1){
        margin:0 20px 0 0;
      }
      &:nth-child(2){
        background: #0083FF;
        &.disable{
          opacity: 0.5;
          cursor:no-drop;
        }
      }
    }
  }
}
</style>