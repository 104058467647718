<template>
  <div class="addImplement">
    <div class="addImplement-wrap">
      <div class="addImplement-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn" src="@/assets/images/pop01.png" alt="" />
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>权限名称</span>
          <div>
            <el-input
              v-model.number="inputs1"
              placeholder="请输入名称"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input flex2">
          <span>设置web模块</span>
          <div class = "selectlist" v-if = "checkList && checkList.length">
            <el-checkbox-group v-model="checkListselected">
              <template v-for = "(item,index) in checkList" :key = "index">
                <el-checkbox :label="item.ID">{{item.Name}}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input flex2">
          <span>设置小程序模块</span>
          <div class = "selectlist"  v-if = "checkList2 && checkList2.length">
            <el-checkbox-group v-model="checkListselected2">
              <template v-for = "(item,index) in checkList2" :key = "index">
                <el-checkbox :label="item.ID">{{item.Name}}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="addImplement-bottom">
        <button @click="closefn" class = "def">取消</button> 
        <button
          @click="AddExtractApplyfn"
          :class="{ disable: !inputs1 || !cannext}"
        >
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { GetMenuList,AddRole,GetRole } from "@/js/systemManagementApi";
export default {
  props: {
    addfalse: Boolean,
    bonus: Number,
    addtitle: String,
    editdata: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      checkListselected:[], //web
      checkListselected2:[], //小程序
      checkList:[],
      checkList2:[],
      inputs1: "", //
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      num: 0,
      showstyle: false,
      addtitle: "",
      editid:'',
      cannext:false
    });
    const closefn = () => {
      emit("addfalsefn");
    };
    const gr = () =>{
      let datas = {
        id:state.editid
      }
      GetRole(datas).then((res)=>{
        console.log(res);
        let {Code,Data,Message} = res.data;
        if(Code == 1 && Data && Data.Rolemenudata && Data.Rolemenudata.length){
          let checkListselected = [];
          let checkListselected2 = [];
          
          Data.Rolemenudata.forEach((v)=>{ // 1 web 2 小程序
            if(v.MenuType == 1){
              checkListselected.push(v.MenuId)
            }else if(v.MenuType == 2){
              checkListselected2.push(v.MenuId)
            }
          })
          if(checkListselected.length){
            state.checkListselected = checkListselected;
          }
          if(checkListselected2.length){
            state.checkListselected2 = checkListselected2;
          }
          state.cannext = true;
        }else{
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      })
    }
    const cdata = getStorage("bscdata");
    const gml = ()=>{
      GetMenuList().then((res)=>{
        console.log(res);
        let {Code,Data,Message} = res.data;
        if(Code == 1 && Data){
          state.checkList = Data.WebMenu;
          state.checkList2 = Data.WeChatMuen;
        }else{
          ElMessage({
            showClose: true,
            message: Message,
            type: "error",
          });
        }
      })
    }
    const methods = {
      checkChange:()=>{
        console.log(state.checkListselected);
      },
      AddExtractApplyfn: () => {
        if (!state.inputs1 || !state.cannext) {
          return;
        }
        let datas = {"Role":{"Name":state.inputs1,"ProjectId":cdata.pid},"ListID":state.checkListselected.concat(state.checkListselected2)}
        if(state.editid){
          datas.Role.ID = state.editid;
        }
        AddRole(datas).then((res)=>{
          let {Code,Message} = res.data;
          if(Code == 1){
            emit("RoleList");
            emit("addfalsefn");
            ElMessage({
              showClose: true,
              message: Message,
              type: "success",
            });
          }else{
            ElMessage({
              showClose: true,
              message: Message,
              type: "error",
            });
          }
        })
      },
    };
    onMounted(() => {
      state.addtitle = props.addtitle;
      if (state.addtitle == "编辑权限") {
        state.inputs1 = props.editdata.Name;
        state.editid=props.editdata.ID;
        gr();
      }
      state.cannext = true;

      gml();
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addImplement {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addImplement-wrap {
    width: 576px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addImplement-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }
  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }
  .addImplement-content {
    width: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addImplement-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      &.flex2{
        align-items: flex-start;
        .el-checkbox-group{
          .el-checkbox{
            margin-bottom:10px;
          }
        }
      }
      span {
        display: inline-block;
        padding-right: 11px;
        box-sizing: border-box;
        word-break: keep-all;
      }
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addImplement-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addImplement-add {
    margin-left: 109px;
    margin-bottom: 20px;
    .addImplement-btn {
      width: 128px;
      height: 32px;
      background: rgba(0, 180, 255, 0.1);
      border-radius: 4px;
      border: 1px solid #0083ff;
      font-size: 12px;
      color: #0083ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        box-sizing: border-box;
      }
    }
  }

  .addImplement-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #adbaae;
      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      // &:nth-child(2) {
        background: #027aff;
        color: #fff;
      // }
      &.def {
        background: #fff;
        color: #adbaae;
      }
      &.disable{
        background: #fff;
        color: #adbaae;
        cursor: no-drop;
        opacity: 0.5;
      }
    }
  }
}
</style>