import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增修改资金回收额
export const UpdateCapitalRecycle=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/DecisionAnalysis/UpdateCapitalRecycle`,
        data:datas
    })
}

// 获取资金回收额
export const GetCapitalRecycle=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/DecisionAnalysis/GetCapitalRecycle`,
        params:datas
    })
}

// 新增修改产值完成额
export const UpdateOutputAccomplish=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/DecisionAnalysis/UpdateOutputAccomplish`,
        data:datas
    })
}


// 获取产值完成额
export const GetOutputAccomplish=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/DecisionAnalysis/GetOutputAccomplish`,
        params:datas
    })
}





